
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildDate": "2024-08-25T15:36:50.815Z",
  "nuxt": {
    "buildId": "5f9ad0c2-9d4d-4094-8cf0-c69c448905b6"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
